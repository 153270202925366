<template>
    <div>
        <div class="w-full flex-row jc-fe">
            <div class="as-s">
                 
            </div>
            <div class="w-21 flex-col">
                <p class="txt-16 fw-t txt-center">annuel</p>
                <p class="txt-blue txt-10 fw-m txt-center" id="annualSubCMOptionsTabHead">par mois</p>
            </div>
            <div class="w-15 flex-row ai-c txt-12 fw-sb">
                mensuel
            </div>
        </div>
        <div class="w-full flex-col gap-sm txt-16 fw-t">
            <div
                v-for="(elem, index) in dataCMOptions" :key="index"
                class="w-full flex-row"
                :id="[ elem.most_popular ? 'mostPopularCMOptionsTabRow' : 'otherCMOptionsTabRow' ]"
                >
                <div class="as-s p-h-sm">
                    {{ elem.label }}
                </div>
                <div class="w-21 txt-right p-r-xs">
                    {{ elem.annual_price }} €
                </div>
                <div class="w-15 flex-row ai-fe txt-12 fw-sb">
                    {{ elem.monthly_price }} €
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// IMPORT
import dbcmOpt from '../databases/__cmOptions'

import { onMounted, ref } from 'vue'

export default {
    name: 'CMOptions',
    setup() {
        class ServicesOptions {
            constructor (label, annual_price, monthly_price, most_popular) {
                this.label = label
                this.annual_price = annual_price
                this.monthly_price = monthly_price
                this.most_popular = most_popular
            }
        }

        let dataCMOptions = ref([])

        const makeDataCMOptions = () => {

            for (const service of dbcmOpt) {
                const new_service = new ServicesOptions(service.label, service.annual_price, service.monthly_price, service.most_popular)
                dataCMOptions.value.push(new_service)
            }
        }

        onMounted(makeDataCMOptions);

        //Return
        return {
            dataCMOptions,
        }
    },
}
</script>