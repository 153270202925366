const cmOptions = [
    {
        label: '1 action/semaine',
        annual_price: 85,
        monthly_price: 89,
    },{
        label: '2 actions/semaine',
        annual_price: 150,
        monthly_price: 169,
        most_popular: true,
    },{
        label: '4 actions/semaine',
        annual_price: 280,
        monthly_price: 299,
    },{
        label: '1 action/jour',
        annual_price: '-',
        monthly_price: 399,
    },
]
module.exports = cmOptions;